import ReactGA from 'react-ga'

export const initGA = () => {
 ReactGA.initialize('UA-197489312-1')
}


export const logPageview = () => {
 ReactGA.set({page: window.location.pathname})
 ReactGA.pageview(window.location.pathname)
}
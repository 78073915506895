import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Hero from '../components/Hero'
import Beliefs from '../components/Beliefs'
import Details from '../components/Details'
import Testimonials from '../components/Testimonials'
import Contact from '../components/Contact'
import {Helmet} from 'react-helmet'
import { logPageview } from '../utils/analytics'

const Wrapper = styled.div`

`

const Landing = () => {
 
 useEffect(() => {
  logPageview()
 }, [])

 return (
  <Wrapper>
   <Helmet>
    <title>Gallant Solutions Dispatch - Freight Dispatching Company | Dispatch Service</title>
    <meta name="description" content="METROMAX DISPATCH | Best Dispatching Company &Dispatch Freight | Competitive Rates | We work with Small & Large Carriers & Owner Operators"/>
    <meta name="keywords" content="dispatch company, trucking companies near me, gallant solutions, gallant dispatching, dispatch services, dispatch service"/>
   </Helmet>
   <Hero />
   <Beliefs />
   <Details />
   <Testimonials />
   <Contact />
  </Wrapper>
 )
}

export default Landing



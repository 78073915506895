import React, {useEffect, useState}from 'react'
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom'
import Landing from './pages/Landing'
import About from './pages/About'
import Contact from './pages/Contact'
import Layout from './hocs/Layout'
import { Helmet } from 'react-helmet'

const App = () => {


  return (
    <div>
      <Helmet>
        <title>Gallant Solutions Dipatching</title>
        <meta name="descritpiton" content="Truck Dispatcher In The Chicago Area"/>
        <html lang="en" />
        <meta property="og:url" content='https://gallantdispatching.com/' />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Landing}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/contact" component={Contact}/>
          </Switch> 
        </Layout>
      </Router>
    </div>
  )
}

export default App

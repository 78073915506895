import React, {useEffect, useState} from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import {initGA} from '../utils/analytics'

const Layout = ({children}) => {
 useEffect(() => {
   if(!window.GA_INTIALIZED) {
    initGA()
    window.GA_INTIALIZED = true
  }
 })

 return (
  <>
  <Navbar />
   {children}
   <Footer />
  </>
 )
}

export default Layout